import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import inputnumber from '@/components/inputNumber.vue'
import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";
import multitime from "@/components/multiTime.vue";

export default {
    inject: ["reload"],
    components: {
        draggable,
        sorttable,
        paging,
        inputnumber,
        addCustomerTarck,
        multitime
    },
    data() {
        return {
            //  -----  查询  ------
            queryData: {
                packageIds: [],
                userIds: [],
            }, //  表单查询数据
            packageStatusList: [], //  包裹状态
            inspectionStatusList: [], //  验货状态
            rejectionStatusList: [], //  拒签状态
            timeTypes: [ //  时间类型
                {
                    value: '0',
                    label: '添加日期'
                },
                {
                    value: '1',
                    label: '入库日期'
                },
                {
                    value: '2',
                    label: '出库日期'
                },
                {
                    value: '3',
                    label: '打包日期'
                },
            ],
            timeType: '0', //  时间类型
            times: [], //  开始结束时间
            startTime_a: '',
            endTime_a: '',





            isActive: true, //  输入框绑定动态class
            judge: false, //  输入框绑定动态class
            MoreConditions: '更多条件', //  输入框绑定动态class

            //      -------  表格  ----------
            pageNum: 1, //  页码
            total: 0, //  总条数
            sizeList: [10, 20, 50, 100, 200],
            size: 20, //  一页几条
            tableData: [], //  表格数据
            numbers: "", //快递单号
            billPackNumber: "", //打包批次号
            billOutStockNumber: "", //出库单号
            showInputBox: false,
            showInputBox2: false,
            tableHeigth: "50vh", //  表格高度
            selectTableData: [], //  选中的数据
            //    会员选择
            isDT: false,
            originalTabelHeadeTitle: [],
            vipNames: '',
            vf: {
                memberId: '',
                nickname: '',
            },
            vfr: {},
            ut: [],
            selectedData: [], //  选中的数据
            pageStart_u: 1, //  分页配置
            pageTotal_u: 20,
            pageCount_u: 0,
            //    ---- 杂项-自定义表头 ----
            show_sortTableHeard: false,
            tabelHeadeTitle: [
                {
                    name: "会员名称",
                    field: "memberNickname",
                    width: "120",
                    sort: 3,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员号",
                    field: "memberId",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "快递单号",
                    field: "courierNumber",
                    width: "160",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "出库单号",
                    field: "billStorepackNumber",
                    width: "160",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "剩余免费库存天数",
                    field: "residueStockDay",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "超出免费库存天数",
                    field: "exceedStockDay",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "转单号",
                    field: "billStorepackChannelNumber",
                    width: "160",
                    sort: 6,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货架号",
                    field: "frameName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹状态",
                    field: "packageStatusShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员付款状态",
                    field: "storepackStatusShow",
                    width: "120",
                    sort: 18,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "拒收状态",
                    field: "isRejectionShow",
                    width: "120",
                    sort: 19,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "验货状态",
                    field: "checkStatusShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货物品类",
                    field: "itemCategoryShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "入库时间",
                    field: "intoStockTime",
                    width: "180",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                }, {
                    name: "仓库",
                    field: "storageName",
                    width: "120",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "包裹件数",
                    field: "boxCount",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹重量",
                    field: "packageWeight",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹体积",
                    field: "packageVolume",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹备注",
                    field: "packageComment",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包批次",
                    field: "billPackNumber",
                    width: "120",
                    sort: 180,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "目的地",
                    field: "areaCountryName",
                    width: "120",
                    sort: 6,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "邮编",
                    field: "postCode",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "添加包裹时间",
                    field: "packageCreateTime",
                    width: "180",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "到库签收时间",
                    field: "billSignforCreateTime",
                    width: "180",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包时间",
                    field: "billStorepackCreateTime",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "出库时间",
                    field: "outStockTime",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                }
            ],
            myTableHeard: [], //  动态表头
            allCheck: false, //  全选
            sortName: '模板名', //  排序名
            selectLength: 0, //  显示的长度
            totalArr: [], //  需要合计的字段
            className: "", //  导出需要
            show_upload: false, //  显示上传弹窗
            fieldName: [], //  下载字段名
            head: [], //  下载表头字段
            fileList: [],
            uploadUrl: "", //  上传路径
            pageGroupName: 'PAGE_ZHCX', //  页面标识
            //日期查询弹框查询条件
            Timedata: {
                startTime: "", //开始时间
                endTime: "", //结束时间
            },
            homePage: 1, //日期查询弹窗分页起始页
            articless: 20, //日期查询每页条数
            pageCounts: "", //日期查询总条数 
            timetype: [], //日期查询弹窗表单
            title: "", //日期查询表单title
            showinfo: false, //日期查询是否弹窗
            timelist: [], //日期弹框添加的数据
            timelistdata: [], //日期弹框勾选的数据
            ut: [],

            // 新建客服跟踪
            showDia1: false,
            documentNumbers: "",
            documentStatus: "", //包裹单据
        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
    },
    created() {
        this.myTableHeard = this.tabelHeadeTitle;
        if (this.originalTabelHeadeTitle.length === 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        this.getGroupFindFieldSort(); //获取用户自定义表头
        this.myGetStatusValList('bill_inspection_v1.check_status', 1) //  获取验货状态
        this.myGetStatusValList('bill_rejection_v1.handle_status', 2) //  获取拒收状态
        this.myGetStatusValList('package_v1.package_status', 3) //  获取包裹状态状态
        let endtime = tools.getCurrentDate(); //今天的时间;
        let sarttime = tools.getFlexDate(-10); //10天前
        this.startTime_a = sarttime
        this.endTime_a = endtime
        // this.times = [sarttime, endtime];
        // this.queryData.addStartTime = sarttime
        // this.queryData.addEndTime = endtime
        this.Timedata.startTime = sarttime
        this.Timedata.endTime = endtime
        this.getData();
        this.getVipList();
    },
    activated() {
        // this.getData();
    },
    mounted() {


        // window.onresize = () => {
        //     return (() => {
        //         this.fetTableHeight();
        //     })();
        // };
    },
    watch: {
        vipNames(a, b) {
            if (!a) {
                this.queryData.userIds = [];
            }
        }
    },
    methods: {

        queryBtn_time(s, e) {
            this.startTime_a = s || ''
            this.endTime_a = e || ''
            this.getData()
        },
        timestatus(type, s, e) {
            this.timeType = type || '0'
            this.startTime_a = s || ''
            this.endTime_a = e || ''

            let Timedata = this.Timedata
            this.Timedata.startTime = this.startTime_a
            this.Timedata.endTime = this.endTime_a
            Timedata.pageStart = this.homePage
            Timedata.pageTotal = this.articless
            if (type == "1") {
                this.timelist = []
                this.timelistdata = []
                this.getIntoStorageBillByDate(Timedata)
            } else if (type == "2") {
                this.timelist = []
                this.timelistdata = []
                this.getOutStorageBillByDate(Timedata)
            } else if (type == "3") {
                this.timelist = []
                this.timelistdata = []
                this.getStorepackBillByDate(Timedata)
            } else if (type == "0") {
                this.timelist = []
                this.timelistdata = []
                this.getData();
            }
        },

        // timestatus(e) {
        //     let Timedata = this.Timedata
        //     Timedata.pageStart = this.homePage
        //     Timedata.pageTotal = this.articless

        //     if (e == "1") {
        //         this.timelist = []
        //         this.timelistdata = []
        //         this.getIntoStorageBillByDate(Timedata)
        //     } else if (e == "2") {
        //         this.timelist = []
        //         this.timelistdata = []
        //         this.getOutStorageBillByDate(Timedata)
        //     } else if (e == "3") {
        //         this.timelist = []
        //         this.timelistdata = []
        //         this.getStorepackBillByDate(Timedata)
        //     } else if (e == "0") {
        //         this.timelist = []
        //         this.timelistdata = []
        //         this.getData();
        //     }
        // },


        // 提交新建客服跟踪
        confirmAdd(formName) {
            this.showDia1 = false;
            this.getData();
        },
        // 关闭重置弹窗数据
        closeDia1(e) {
            this.showDia1 = e || false;
            this.documentNumbers = '';//如果不重置这个,第二次点开同样的数据组件不会监听到变化默认是空的
        },


        rowclick(row, event, column) {
            this.$refs.mytable.toggleRowSelection(row);
            // @row-click="rowclick"
        },
        rowclick2(row, event, column) {
            this.$refs.memberTable.toggleRowSelection(row);
        },
        //点击输入快递单号
        clickInput() {
            this.showInputBox = true
        },
        showInputBoxBtn(val) {
            this.showInputBox = false
            this.numbers = val;
            this.getData();
        },
        closeDiasInputVal(val) {
            this.showInputBox = val || false
        },
        //点击输入打包批次号
        clickInput2() {
            this.showInputBox2 = true
        },
        showInputBoxBtn2(val) {
            this.showInputBox2 = false
            this.billPackNumber = val;
            this.getData();
        },
        closeDiasInputVal2(val) {
            this.showInputBox2 = val || false
        },

        //选择验货状态
        changeCheckStatus(e) {
            console.log(e)
            this.getData();
        },
        // 包裹状态
        changepackStatus(e) {
            console.log(e)
            this.getData();
        },


        //入库数据勾选
        TickData(e) {
            this.timelistdata = e
            console.log(this.timelistdata)
        },
        // 关闭三种日期弹窗
        closeTimeType() {

            if (this.timelistdata.length < 1) {
                this.timeType = '0'
            }
        },
        //入库添加id
        addnid() {
            let that = this;
            if (that.timelistdata.length < 1) {
                that.$message.error('还没选择任何数据哦~');
                return false
            }
            // that.$message.success("添加了" + that.timelistdata.length + "条数据")
            that.timelistdata.forEach((item) => {
                that.timelist.push(item.packageId)
            })
            // console.log(that.timelist)
            setTimeout(() => {
                that.showinfo = false
                that.getData();
            }, 500);
        },
        //出库添加idstorepackId
        addnid1() {
            let that = this;
            if (that.timelistdata.length < 1) {
                that.$message.error('还没选择任何数据哦~');
                return false
            }
            this.timelistdata.forEach((item) => {
                this.timelist.push(item.outStorageId)
            })
            console.log(this.timelist)
            setTimeout(() => {
                this.showinfo = false
                that.getData();
            }, 500);
        },
        //打包添加id
        addnid2() {
            let that = this;
            if (that.timelistdata.length < 1) {
                that.$message.error('还没选择任何数据哦~');
                return false
            }
            this.timelistdata.forEach((item) => {
                this.timelist.push(item.storepackId)
            })
            console.log(this.timelist)
            setTimeout(() => {
                this.showinfo = false
                that.getData();
            }, 500);
        },


        //入库时间获取入库信息
        getIntoStorageBillByDate(Timedata) {
            Api.getIntoStorageBillByDate(Timedata).then((res) => {
                if (res.data.result.data) {
                    this.timetype = res.data.result.data || []
                    this.title = "入库信息"
                    this.showinfo = true
                    this.pageCounts = res.data.result.pageCount

                } else {
                    this.$message.warning('没有该时间段的入库信息')
                }
            })
        },
        //出库时间获取出库信息 
        getOutStorageBillByDate(Timedata) {
            Api.getOutStorageBillByDate(Timedata).then((res) => {
                if (res.data.result.data) {
                    this.timetype = res.data.result.data || []
                    this.title = "出库信息"
                    this.showinfo = true
                    this.pageCounts = res.data.result.pageCount

                } else {
                    this.$message.warning('没有该时间段的出库信息 ')
                }
            })
        },
        //打包时间获取打包信息 
        getStorepackBillByDate(Timedata) {
            Api.getStorepackBillByDate(Timedata).then((res) => {
                if (res.data.result.data) {
                    this.timetype = res.data.result.data || []
                    this.title = "打包信息"
                    this.showinfo = true
                    this.pageCounts = res.data.result.pageCount

                } else {
                    this.$message.warning('没有该时间段的打包信息 ')
                }
            })

        },
        //表格样式i
        tableRowClassName({
            row,
            rowIndex
        }) {
            if (rowIndex % 2 === 0) {
                return "warning-row";
            }
        },
        getData() { //   获取数据
            this.queryData.packageIds = this.timelist
            let numbers = tools.getOrderNum(this.numbers)
            let billPackNumber = tools.getOrderNum(this.billPackNumber)
            let billOutStockNumber = tools.getOrderNum(this.billOutStockNumber) || []
            if (this.timeType != 0) {
                this.queryData.addStartTime = ''
                this.queryData.addEndTime = ''
            } else {
                if (this.startTime_a) {
                    this.queryData.addStartTime = this.startTime_a + ' 00:00:00';
                } else {
                    this.queryData.addStartTime = '';
                }
                if (this.endTime_a) {
                    this.queryData.addEndTime = this.endTime_a + ' 23:59:59';
                } else {
                    this.queryData.addEndTime = '';
                }
                // if (this.times && this.times.length > 0) {
                //     this.queryData.addStartTime = this.times[0] + ' 00:00:00';
                //     this.queryData.addEndTime = this.times[1] + ' 23:59:59';
                // } else {
                //     this.queryData.addStartTime = ''
                //     this.queryData.addEndTime = ''
                // }

            }
            this.queryData.courierNumber = numbers
            this.queryData.billPackNumber = billPackNumber
            this.queryData.billOutStockNumber = billOutStockNumber
            this.queryData.pageStart = this.pageNum;
            this.queryData.pageTotal = this.size;
            Api.packageList(this.queryData).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData = res.data.result.data || [];
                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                } else {
                    this.$message.error(`${res.data.message}---综合查询列表`)
                }
            });
        },

        queryBtn_ok() { //  查询按钮
            this.pageNum = 1;
            this.getData();
        },

        queryBtn_cz() { //  重置按钮
            this.timelistdata = []
            this.timelist = []
            this.queryData = {
                packageIds: [],
                userIds: [],
            }
            this.numbers = ""
            this.vipNames = ''
            this.billOutStockNumber = ''
            this.times = []
        },

        more() { //  输入框绑定动态class
            this.isActive = !this.isActive
            this.judge = !this.judge
            this.fetTableHeight();
            if (this.judge) {
                this.MoreConditions = '收起条件'
            } else {
                this.MoreConditions = '更多条件'
            }
        },
        handleSizeChanges(val) {
            this.homePage = 1
            this.articless = val
            // this.timestatus()
        },
        handleCurrentChanges(val) {
            this.homePage = val
            // this.timestatus()
        },
        handleSizeChange(val) { //  分页
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) { //  分页
            this.pageNum = val;
            this.getData();
        },

        AddPackage() { //  添加包裹
            this.$router.push({
                path: "/BusinessManagement/addPacks",
            });
        },

        handleSelectionChange(e) { // 表格选择事件
            this.selectTableData = e;
        },
        ModifyPackage() { //  修改包裹
            if (this.selectTableData.length === 1) {
                this.$router.push({
                    path: '/BusinessManagement/addPacks',
                    query: {
                        packageId: this.selectTableData[0].packageId
                    }
                });
            } else {
                this.$message.error('请选一个需要修改的包裹')
            }
        },

        PackageDetails(data) { //  包裹详情
            if (data) {
                this.$router.push({
                    path: '/BusinessManagement/packsDetails',
                    query: {
                        packageId: data.packageId,
                        courierNumber: data.courierNumber,
                    }
                });
            } else {
                if (this.selectTableData.length > 0) {
                    this.$router.push({
                        path: '/BusinessManagement/packsDetails',
                        query: {
                            packageId: this.selectTableData[0].packageId,
                            courierNumber: this.selectTableData[0].courierNumber,
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/BusinessManagement/packsDetails',
                    });
                }
            }


        },
        dabaoDetails() { //  包裹详情
            if (this.selectTableData.length === 1) {
                if (this.selectTableData[0].packageStatus == 'chu_ku_zai_tu:package_status' ||
                    this.selectTableData[0].packageStatus == 'ke_hu_qian_shou:package_status' ||
                    this.selectTableData[0].packageStatus == 'chu_ku_zhong:package_status' ||
                    this.selectTableData[0].packageStatus == 'da_bao_wan_cheng:package_status') {
                    this.$router.push({
                        path: '/BusinessManagement/dabaoDetails',
                        query: {
                            packageId: this.selectTableData[0].packageId,
                        }
                    });
                } else {

                    this.$message.error('包裹未打包完成')
                }

            } else {
                this.$message.error('请选择一个包裹')
            }
        },

        genzongBtns() {
            let str = '';
            console.log(this.selectTableData)
            this.selectTableData.forEach(item => {
                str += item.courierNumber + ','
            })
            if (str.length > 0) {
                str = str.substring(0, str.length - 1)
            }
            this.documentNumbers = str;
            this.documentStatus = 'package_v1:document_status'//包裹单据
            this.showDia1 = true

        },

        //包裹作废
        Package_zf() {
            if (this.selectTableData.length === 1) {
                this.$confirm("是否作废该包裹?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    var param = {
                        packageId: this.selectTableData[0].packageId,
                    }
                    param.sign = tools.getSign(param);
                    Api.delPackage(param).then(res => {
                        if (res.data.status === 'success') {
                            this.$message.success(res.data.message)
                            this.getData()
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
                })
            } else {
                this.$message.error('请选择一个包裹')
            }
        },


        handleCommand_dc(command) { //  导出操作
            if (command === 'dc') {
                // 导出
                this.exportBtn()
            } else if (command === 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command === 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },

        myGetStatusValList(status, num) {
            Api.getStatusValList({
                tableAndFieldName: status
            }).then(res => {
                if (res.data.status === 'success') {
                    switch (num) {
                        case 1:
                            //   验货
                            this.inspectionStatusList = res.data.result
                            break
                        case 2:
                            //  拒收
                            this.rejectionStatusList = res.data.result
                            break
                        case 3:
                            //  包裹状态
                            this.packageStatusList = res.data.result
                            break
                    }
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property === that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },
        //   -------- 编辑会员 -----------
        getMemberInfo() { //  选择会员名称
            this.getVipList()
            this.isDT = true
        },


        multiSelect(val) {
            this.selectedData = val
        },
        xz() { //  会员表格选择事件
            let arr = []
            let names = []
            this.selectedData.forEach(item => {
                arr.push(item.userId)
                names.push(item.memberId)
            })
            this.selectedData = []
            this.vipNames = names.join(',')
            this.queryData.userIds = arr
            this.isDT = false

            this.getData();
        },
        cxEdit() { //  查询按钮
            this.pageStart_u = 1
            this.getVipList()
        },
        psc_u(val) { //  分页事件
            this.pageTotal_u = val
            this.getVipList()
        },
        pcc_u(val) { //  分页事件
            this.pageStart_u = val
            this.getVipList()
        },

        handleClose() { //  会员弹出框关闭前回调事件
            this.resetData('vf')
        },
        resetData(formName) {
            this.$refs[formName].resetFields()
        },
        changeRejectStatus() { //  拒收状态选择
        },

        //   ------------- 设置表格动态高度  ------------

        resetHeight() { // 重置table高度
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },

        fetTableHeight() { // 设置table高度
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },

        getHeight() { // 获取表格高度
            let windonHeight = document.body.clientHeight
            let queryHeigth
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 40; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
            return Math.floor(windonHeight - otherHeight) || "50vh"; //
        },

        //    -------------  设置列表表头方法  ------------

        getGroupFindFieldSort() { //  获取自定义的排序,没有就用默认的
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        this.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    this.myTableHeard = this.tabelHeadeTitle;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isTotal) {
                        totalArr.push(this.myTableHeard[i].field);
                    }
                }
                this.totalArr = totalArr;
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },

        myModel() { //  设置模板按钮
            this.show_sortTableHeard = true;
            this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
                if (this.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength = selectLength;
            if (selectLength === this.tabelHeadeTitle.length) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }
        },

        cancelSortHeard() { //   取消按钮
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard = e;
        },
        //      -----------  导出功能   ------------
        // 上传导出模板
        uploadSectionFile(param) {
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldNames.push(this.myTableHeard[i].field);
                        heads.push(this.myTableHeard[i].name);
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let that = this;
            let datas = that.tableData;
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: that.className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);

            Api.templateDataExport({
                customizedExportId: customizedExportId,
                className: that.className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.style.display = 'none';
                a.href = downloadurl;
                a.target = '_blank'
                a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];
            console.log('this.myTableHeard')
            console.log(this.myTableHeard)
            for (var i = 0; i < this.myTableHeard.length; i++) {
                if (this.myTableHeard[i].isShow) {
                    fieldName.push(this.myTableHeard[i].field);
                    head.push(this.myTableHeard[i].name);
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    tools.dynamicExport(
                        this.myTableHeard,
                        this.className,
                        this.tableData,
                        "综合查询列表"
                    );
                }
            });
        },


        // 选择会员
        huiayu(e) {
            this.ut.forEach((item) => {
                let arr = []
                let names = []
                if (e == item.memberId) {
                    this.queryData.userIds = [];
                    arr.push(item.userId)
                    names.push(item.memberId)
                    this.vipNames = names.join(',')
                    this.queryData.userIds = arr
                    this.getData()
                }
            });
        },
        // 远程搜索会员
        getMembers(e) {
            this.getVipList(e);

        },
        // getVipList() { //  获取会员列表
        //     this.vf.pageStart = this.pageStart_u
        //     this.vf.pageTotal = this.pageTotal_u
        //     Api.getMemberDownList(this.vf).then((res) => {
        //         if (res.data.status === 'success') {
        //             this.ut = res.data.result.data
        //             this.pageCount_u = res.data.result.pageCount || 0;
        //         }
        //     });
        // },
        getVipList(e) {

            Api.getMemberDownList({
                memberId: this.isDT ? this.vf.memberId : (e || ""),
                nickname: this.vf.nickname || '',
                pageStart: this.pageStart_u,
                pageTotal: this.pageTotal_u,
            }).then((res) => {
                if (res.data.status === "success") {
                    this.ut = res.data.result.data || [];
                    // this.total = res.data.result.pageCount || 0;
                    this.pageCount_u = res.data.result.pageCount || 0;
                }
            });
        },
    },
};